import Vue from "vue";
import App from "./App";
import { store } from "./core/store";
import { router } from './router';

import "bootstrap";
window.bootstrap = require("bootstrap/dist/js/bootstrap.bundle.js");

import "popper.js";
import "./core/plugins/vuex";
import "./core/plugins/bootstrap-vue";
import "./core/plugins/inline-svg";
import "./core/plugins/vee-validate";
import "./core/plugins/datepicker";
import "./core/plugins/multiselect";
import "./core/plugins/fontawesome";
import "./core/plugins/toaster";
import "./core/plugins/vue-simple-alert";
import "./core/plugins/jquery";

import "./assets/js/scripts.bundle";
import "./assets/js/plugins.bundle";

new Vue({
  el: "#app",
  router,
  store,
  render: (h) => h(App),
});
