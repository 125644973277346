var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "d-flex flex-column flex-root" }, [
    _c(
      "div",
      { staticClass: "page d-flex flex-row flex-column-fluid" },
      [
        _c("Aside"),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "wrapper d-flex flex-column flex-row-fluid",
            attrs: { id: "kt_wrapper" }
          },
          [
            _c("Header"),
            _vm._v(" "),
            _c("router-view"),
            _vm._v(" "),
            _c("Footer")
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-9ec71a3c", { render: render, staticRenderFns: staticRenderFns })
  }
}