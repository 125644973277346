import Vue from "vue";
import { BootstrapVue} from 'bootstrap-vue' ;

Vue.use(BootstrapVue);

import { IconsPlugin } from 'bootstrap-vue'
Vue.use(IconsPlugin);

import { BootstrapVueIcons } from 'bootstrap-vue'
Vue.use(BootstrapVueIcons);

import { BFormInput } from 'bootstrap-vue'
Vue.component('b-form-input', BFormInput);

import { BTable } from 'bootstrap-vue'
Vue.component('b-table', BTable);

import { BFormGroup } from 'bootstrap-vue'
Vue.component('b-form-group', BFormGroup);

import { BButton } from 'bootstrap-vue'
Vue.component('b-button', BButton);

import { BModal } from 'bootstrap-vue'
Vue.component('b-modal', BModal);

import { VBModal } from 'bootstrap-vue'
Vue.directive('b-modal', VBModal);

import { ModalPlugin } from 'bootstrap-vue'
Vue.use(ModalPlugin);

import { BTabs } from 'bootstrap-vue'
Vue.component('b-tabs', BTabs);
import { TabsPlugin } from 'bootstrap-vue'
Vue.use(TabsPlugin);

import { BNav } from 'bootstrap-vue'
Vue.component('b-nav', BNav);

import { NavbarPlugin } from 'bootstrap-vue'
Vue.use(NavbarPlugin);

import { NavPlugin } from 'bootstrap-vue'
Vue.use(NavPlugin);

import { BFormDatepicker } from 'bootstrap-vue'
Vue.component('b-form-datepicker', BFormDatepicker);

import { BTime } from 'bootstrap-vue'
Vue.component('b-time', BTime);

import { BPagination } from 'bootstrap-vue'
Vue.component('b-pagination', BPagination);

import { BTooltip } from 'bootstrap-vue'
Vue.component('b-tooltip', BTooltip)

import { TooltipPlugin } from 'bootstrap-vue'
Vue.use(TooltipPlugin)

import { BAvatar } from 'bootstrap-vue'
Vue.component('b-avatar', BAvatar);

import { AvatarPlugin } from 'bootstrap-vue'
Vue.use(AvatarPlugin);

import { BToast } from 'bootstrap-vue'
Vue.component('b-toast', BToast);

import { ToastPlugin } from 'bootstrap-vue'
Vue.use(ToastPlugin)