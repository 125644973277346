import Vue from "vue";
import Router from "vue-router";

import HomePage from "./views/HomePage.vue";
import LoginPage from "./views/auth/LoginPage.vue";
import Dashboard from "./views/Dashboard.vue";

Vue.use(Router);

export const router = new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      component: HomePage,
      children: [
        { path: "/", redirect: "/home" },
        { path: "/home", name: "Home", component: Dashboard },
       
        {
          path: "/transactions",
          name: "transactions",
          component: () => import("./views/pages/transactions/main.vue"),
          children: [
            { path: "/transactions", redirect: "/transactions" },
            {
              path: "list",
              name: "transactions-list",
              component: () => import("./views/pages/transactions/list.vue"),
            },
            {
              path: "show-inquiries",
              name: "show-inquiries-transactions",
              component: () => import("./views/pages/transactions/show-inquiries.vue"),
            },
           
          ],
        },
        // requests
        {
          path: "/requests",
          name: "requests",
          component: () => import("./views/pages/requests/main.vue"),
          children: [
            { path: "/requests", redirect: "/requests" },
            {
              path: "list",
              name: "requests-list",
              component: () => import("./views/pages/requests/list.vue"),
            },
            {
              path: "form/:id",
              name: "requests-form",
              component: () => import("./views/pages/requests/form.vue"),
            },
          ],
        },

        // suggestions
        {
          path: "/suggestions",
          name: "suggestions",
          component: () => import("./views/pages/suggestions/main.vue"),
          children: [
            { path: "/suggestions", redirect: "/suggestions" },
            {
              path: "list",
              name: "suggestions-list",
              component: () => import("./views/pages/suggestions/list.vue"),
            },
            {
              path: "form/:id",
              name: "suggestions-form",
              component: () => import("./views/pages/suggestions/form.vue"),
            },
          ],
        },

        // complaints
        {
          path: "/complaints",
          name: "complaints",
          component: () => import("./views/pages/complaints/main.vue"),
          children: [
            { path: "/complaints", redirect: "/complaints" },
            {
              path: "list",
              name: "complaints-list",
              component: () => import("./views/pages/complaints/list.vue"),
            },
            {
              path: "form/:id",
              name: "complaints-form",
              component: () => import("./views/pages/complaints/form.vue"),
            },
          ],
        },

      ],
    },
    { path: "/login", component: LoginPage },

    // otherwise redirect to home
    { path: "*", redirect: "/" },
  ],
});

router.beforeEach((to, from, next) => {
    // redirect to login page if not logged in and trying to access a restricted page
    const publicPages = ['/login','/reset-password'];
    const authRequired = !publicPages.includes(to.path);

    const card = localStorage.getItem('user');
    const token = localStorage.getItem('token');
    const name = localStorage.getItem('name');
    const ntoken = localStorage.getItem('ntoken');

    if (authRequired && (!card || !token || !name || !ntoken)) {
        return next('/login');
    }

    next();
})
