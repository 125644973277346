<template>
  <!--begin::Main-->
  <div class="d-flex flex-column flex-root">
    <!--begin::Authentication - Sign-in -->
    <div class="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed"
         v-bind:style="{ 'background-image': 'url(' + bgSrc + ')' }">
      <!--begin::Content-->
      <div class="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20">
        <!--begin::Logo-->
        <!--end::Logo-->
        <!--begin::Wrapper-->
        <div class="w-lg-500px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto mt-12">
          <!--begin::Form-->
          <ValidationObserver v-slot="{ invalid }">
            <form name="form" class="form w-100">
              <!--begin::Heading-->
              <div class="text-center mb-7 mt-5">
                <!--begin::Title-->
                <a class="mb-12">
                  <img alt="Logo" :src="bgLogo" class="h-150px" />
                </a>
                <br>
                <h1 class="text-success mb-3 mt-5">تسجيل دخول المواطنين<br>نظام خدمات البلدية</h1>
                <!--end::Title-->
              </div>
              <!--begin::Heading-->
              <div class="fv-row">
                <div class="form-group">
                  <a  class="btn btn-lg btn-success  w-100 mb-5 btn-block" type="button" :href="url">
                    اضغط لتسجيل الدخول
                  </a>
                </div>
              </div>
            </form>
          </ValidationObserver>
        </div>
        <!--end::Wrapper-->
      </div>
      <!--end::Content-->
    </div>
    <!--end::Authentication - Sign-in-->
  </div>
  <!--end::Main-->
</template>

<script>
    import bgImg from  '../../assets/media/illustrations/sketchy-1/14.png'
    import bgLogo from  '../../assets/media/logos/logo-png.png'
    import ApiServices from "../../core/services/api.services";

    export default {
        data () {
            return {
                bgSrc : bgImg ,
                bgLogo : bgLogo ,
                id_number: '',
                url: '',
                submitted: false ,
                loading: false
            }
        },
        created () {
            this.url = ApiServices.getMainCpPath()+'sso.php';
        },
        methods: {
            getMainCpPath (e) {
                let x = this;
            }
        }
    };
</script>
