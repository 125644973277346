<template>
  <div
      id="kt_aside"
      class="Aside aside aside-dark aside-hoverable"
      data-kt-drawer="true"
      data-kt-drawer-name="aside"
      data-kt-drawer-activate="{default: true, lg: false}"
      data-kt-drawer-overlay="true"
      data-kt-drawer-width="{default:'200px', '300px': '250px'}"
      data-kt-drawer-direction="start"
  >
    <!--begin::Brand-->
    <div class="aside-logo flex-column-auto" id="kt_aside_logo">

      <!--begin::Aside toggler-->
      <div
          id="kt_aside_toggle"
          class="btn btn-icon w-auto px-2 btn-active-color-primary aside-toggle"
          @click="changeMainAside()"
          data-kt-toggle="true"
          data-kt-toggle-state="active"
          data-kt-toggle-target="body"
          data-kt-toggle-name="aside-minimize"
      >
        <!--begin::Svg Icon | path: icons/duotune/arrows/arr074.svg-->
        <span class="svg-icon svg-icon-1 rotate-180">
          <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
          >
            <path
                d="M11.2657 11.4343L15.45 7.25C15.8642 6.83579 15.8642 6.16421 15.45 5.75C15.0358 5.33579 14.3642 5.33579 13.95 5.75L8.40712 11.2929C8.01659 11.6834 8.01659 12.3166 8.40712 12.7071L13.95 18.25C14.3642 18.6642 15.0358 18.6642 15.45 18.25C15.8642 17.8358 15.8642 17.1642 15.45 16.75L11.2657 12.5657C10.9533 12.2533 10.9533 11.7467 11.2657 11.4343Z"
                fill="black"
            />
          </svg>
        </span>
        <!--end::Svg Icon-->
      </div>
      <!--end::Aside toggler-->
    </div>
    <!--end::Brand-->
    <!--begin::Aside menu-->
    <div class="aside-menu flex-column-fluid">
      <!--begin::Aside Menu-->
      <div
          class="hover-scroll-overlay-y my-2 py-5 py-lg-8 scroll"
          style="max-height: 90vh; position: relative"
          id="kt_aside_menu_wrapper"
          data-kt-scroll="true"
          data-kt-scroll-activate="{default: false, lg: true}"
          data-kt-scroll-height="auto"
          data-kt-scroll-dependencies="#kt_aside_logo, #kt_aside_footer"
          data-kt-scroll-wrappers="#kt_aside_menu"
          data-kt-scroll-offset="0"
      >
        <!--begin::Menu-->
        <div
            class="
            menu
            menu-column
            menu-title-gray-800
            menu-state-title-primary
            menu-state-icon-primary
            menu-state-bullet-primary
            menu-arrow-gray-500
          "
            id="#kt_aside_menu"
            data-kt-menu="true"
        >


          <div class="menu-item text-center" @click="otherClick()" v-if='!lgStatus'>
            <img alt="Logo" :src="logoImgSrc" class="h-130px"  style="width: 130px; margin-bottom:10px;"/>
          </div>

          <div class="menu-item" @click="otherClick()">
            <router-link
                to="/home"
                v-slot="{ href, route, navigate, isActive, isExactActive }"
                custom
            >
              <span
                  class="menu-link"
                  v-bind:class="{ active: isActive }"
                  :active="isActive"
                  :href="href"
                  @click="navigate"
              >
                <span class="menu-icon"
                ><span class="fa fa-home fs-3"></span
                ></span>
                <span class="menu-title">الرئيسية</span>
              </span>
            </router-link>
          </div>
            <div class="menu-item" @click="otherClick()">
                        <router-link
                            to="/transactions/list"
                            v-slot="{ href, route, navigate, isActive, isExactActive }"
                            custom
                        >
                          <span
                              class="menu-link"
                              v-bind:class="{ active: isActive }"
                              :active="isActive"
                              :href="href"
                              @click="navigate"
                          >
                            <span class="menu-icon"
                            ><span class="fas fa-wallet fs-3"></span
                            ></span>
                            <span class="menu-title">معاملاتي</span>
                          </span>
                        </router-link>
                      </div>
              <div class="menu-item" @click="changeStatus('requests')">
                <div
                    id="requests_menu"
                    class="menu-item menu-accordion"
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    v-bind:class="{ show: SubOpen.requests }"
                >
                  <span class="menu-link">
                    <span class="menu-icon">
                      <span class="fas fa-wallet fs-3"></span>
                    </span>
                    <span class="menu-title"> طلبات المعاملات </span>
                    <span class="menu-arrow"></span>
                  </span>
                  <div
                      id="requests_sub_menu"
                      class="menu-sub menu-sub-accordion"
                      aria-haspopup="true"
                      v-bind:class="{ show: SubOpen.requests }"
                      style=""
                      kt-hidden-height="220"
                  >
                    <router-link
                        to="/requests/form/0"
                        v-slot="{ href, route, navigate, isActive, isExactActive }"
                        custom
                    >
                      <div class="menu-item" @click="otherClick()">
                        <span
                            class="menu-link"
                            v-bind:class="{
                            active:
                              isActive ||
                              hasActiveChildren('/requests/form'),
                          }"
                            :active="isActive"
                            :href="href"
                            @click="navigate"
                        >
                          <span class="menu-bullet">
                            <span class="fas fa-plus"></span>
                          </span>
                          <span class="menu-title">  اضافة طلب معاملة </span>
                        </span>
                      </div>
                    </router-link>
                    <router-link
                        to="/requests/list"
                        v-slot="{ href, route, navigate, isActive, isExactActive }"
                        custom
                    >
                      <div class="menu-item" @click="otherClick()">
                        <span
                            class="menu-link"
                            v-bind:class="{
                            active:
                              isActive ||
                              hasActiveChildren('/requests/list'),
                          }"
                            :active="isActive"
                            :href="href"
                            @click="navigate"
                        >
                          <span class="menu-bullet">
                            <span class="fas fa-table"></span>
                          </span>
                          <span class="menu-title">طلبات المعاملات </span>
                        </span>
                      </div>
                    </router-link>
                  </div>
                </div>
              </div>

              <div class="menu-item" @click="changeStatus('complaints')">
                <div
                    id="complaints_menu"
                    class="menu-item menu-accordion"
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    v-bind:class="{ show: SubOpen.complaints }"
                >
                  <span class="menu-link">
                    <span class="menu-icon">
                      <span class="bi bi-briefcase fs-3"></span>
                    </span>
                    <span class="menu-title"> الشكاوي </span>
                    <span class="menu-arrow"></span>
                  </span>
                  <div
                      id="complaints_sub_menu"
                      class="menu-sub menu-sub-accordion"
                      aria-haspopup="true"
                      v-bind:class="{ show: SubOpen.complaints }"
                      style=""
                      kt-hidden-height="220"
                  >
                    <router-link to="/complaints/form/0"
                        v-slot="{ href, route, navigate, isActive, isExactActive }"
                        custom
                    >
                      <div class="menu-item" @click="otherClick()">
                        <span
                            class="menu-link"
                            v-bind:class="{
                            active:
                              isActive ||
                              hasActiveChildren('/complaints/form'),
                          }"
                            :active="isActive"
                            :href="href"
                            @click="navigate"
                        >
                          <span class="menu-bullet">
                            <span class="fas fa-plus"></span>
                          </span>
                          <span class="menu-title">  تقديم شكوى </span>
                        </span>
                      </div>
                    </router-link>
                    <router-link
                        to="/complaints/list"
                        v-slot="{ href, route, navigate, isActive, isExactActive }"
                        custom
                    >
                      <div class="menu-item" @click="otherClick()">
                        <span
                            class="menu-link"
                            v-bind:class="{
                            active:
                              isActive ||
                              hasActiveChildren('/complaints/list'),
                          }"
                            :active="isActive"
                            :href="href"
                            @click="navigate"
                        >
                          <span class="menu-bullet">
                            <span class="fas fa-table"></span>
                          </span>
                          <span class="menu-title">الشكاوي </span>
                        </span>
                      </div>
                    </router-link>
                  </div>
                </div>
              </div>

              <div class="menu-item" @click="changeStatus('suggestions')">
                <div
                    id="suggestions_menu"
                    class="menu-item menu-accordion"
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    v-bind:class="{ show: SubOpen.suggestions }"
                >
                  <span class="menu-link">
                    <span class="menu-icon">
                      <span class="fas fa-tags fs-3"></span>
                    </span>
                    <span class="menu-title"> المقترحات </span>
                    <span class="menu-arrow"></span>
                  </span>
                  <div
                      id="suggestions_sub_menu"
                      class="menu-sub menu-sub-accordion"
                      aria-haspopup="true"
                      v-bind:class="{ show: SubOpen.suggestions }"
                      style=""
                      kt-hidden-height="220"
                  >
                    <router-link to="/suggestions/form/0"
                                 v-slot="{ href, route, navigate, isActive, isExactActive }"
                                 custom>
                      <div class="menu-item" @click="otherClick()">
                        <span class="menu-link"
                              v-bind:class="{ active: isActive || hasActiveChildren('/suggestions/form'), }"
                              :active="isActive" :href="href" @click="navigate">
                          <span class="menu-bullet">
                            <span class="fas fa-plus"></span>
                          </span>
                          <span class="menu-title">
                          تقديم مقترح
                          </span>
                        </span>
                      </div>
                    </router-link>
                    <router-link
                        to="/suggestions/list"
                        v-slot="{ href, route, navigate, isActive, isExactActive }"
                        custom
                    >
                      <div class="menu-item" @click="otherClick()">
                        <span
                            class="menu-link"
                            v-bind:class="{
                            active:
                              isActive ||
                              hasActiveChildren('/suggestions/list'),
                          }"
                            :active="isActive"
                            :href="href"
                            @click="navigate"
                        >
                          <span class="menu-bullet">
                            <span class="fas fa-table"></span>
                          </span>
                          <span class="menu-title">المقترحات </span>
                        </span>
                      </div>
                    </router-link>
                  </div>
                </div>
              </div>

        </div>
        <!--end::Menu-->
      </div>
    </div>
    <!--end::Aside menu-->
    <!--begin::Footer-->

    <!--end::Footer-->
  </div>
</template>

<script>
import logoImg from "../assets/media/logos/logo-png.png";

export default {
  name: "Aside",
  data() {
    return {
      logoImgSrc : logoImg ,
      SubOpen: {
        requests: false,
        suggestions: false,
        complaints: false,
        // transactions:false,
      },
     lgStatus: false,

    };
  },
  methods: {
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    },
    changeStatus(target) {
      if (target === "requests") {
        var m_menu = document.getElementById("requests_menu");
        var menu_class = m_menu.className;
        if (menu_class === "menu-item menu-accordion") {
          this.SubOpen.requests = true;
        } else {
          this.SubOpen.requests = false;
        }
      }
      else if (target === "suggestions") {
        var m_menu = document.getElementById("suggestions_menu");
        var menu_class = m_menu.className;
        if (menu_class === "menu-item menu-accordion") {
          this.SubOpen.suggestions = true;
        } else {
          this.SubOpen.suggestions = false;
        }

      }
      //transactions
      //   else if (target === "transactions") {
      //   var m_menu = document.getElementById("transactions_menu");
      //   var menu_class = m_menu.className;
      //   if (menu_class === "menu-item menu-accordion") {
      //     this.SubOpen.transactions = true;
      //   } else {
      //     this.SubOpen.transactions = false;
      //   }

      // }
      else if (target === "complaints") {
        var m_menu = document.getElementById("complaints_menu");
        var menu_class = m_menu.className;
        if (menu_class === "menu-item menu-accordion") {
          this.SubOpen.complaints = true;
        } else {
          this.SubOpen.complaints = false;
        }
      }

    },
    otherClick() {
      this.SubOpen = {
        requests: false,
        suggestions: false,
        complaints: false,
        transactions:false,

      };
    },
    changeMainAside() {
      var body_id = document.getElementById("kt_body");
      var icon_id = document.getElementById("kt_aside_toggle");

      var main_menu_status = body_id.getAttribute("data-kt-aside-minimize");
      if (main_menu_status != null && main_menu_status === "on") {
        icon_id.className =
            "btn btn-icon w-auto px-0 btn-active-color-primary aside-toggle ";

        body_id.setAttribute("data-kt-aside-minimize", "off");
            this.lgStatus = false;
     } else {
        body_id.setAttribute("data-kt-aside-minimize", "on");
        icon_id.className =
            "btn btn-icon w-auto px-0 btn-active-color-primary aside-toggle active";
           this.lgStatus = true;
   }
    },
    closeMenu() {
      var constants_menu_id = document.getElementById("constants_menu");
    },
  },
  mounted() {
    let route = this.$route["path"];


    let requests = false ;
    if (route.includes("/requests"))
      requests = true ;

    let suggestions = false ;
    if (route.includes("/suggestions"))
      suggestions = true ;

    let complaints = false ;
    if (route.includes("/complaints"))
      complaints = true ;

//transactions
  // if (route.includes("/transactions"))
  //     transactions = true ;

    this.SubOpen = { requests: requests, suggestions: suggestions, complaints: complaints};
  },
  computed: {},
  created: function () {},
};
window.onload = function () {
  var show = false;
  var mobileFunction = function () {
    var kt_aside_id = document.getElementById("kt_aside");
    var hidediv = document.getElementById("hidediv");
    var body_id = document.getElementById("kt_body");
    var mobile_main_menu_status = body_id.getAttribute("data-kt-drawer-aside");
    var body_id = document.getElementById("kt_body");
    //----------

    if (mobile_main_menu_status != null && mobile_main_menu_status === "on") {
      body_id.setAttribute("data-kt-drawer-aside", "off");
      body_id.setAttribute("data-kt-drawer", "off");
    } else {
      body_id.setAttribute("data-kt-drawer-aside", "on");
      body_id.setAttribute("data-kt-drawer", "on");
      // hidediv.style.display="block"
    }
    //---------
    if (
        kt_aside_id.className === "Aside aside aside-dark aside-hoverable" ||
        kt_aside_id.className ===
        "Aside aside aside-dark aside-hoverable drawer drawer-start"
    ) {
      kt_aside_id.className =
          "Aside aside aside-dark aside-hoverable drawer drawer-start drawer-on";
      hidediv.style.display = "block";
      show = true;
    } else {
      kt_aside_id.className =
          "Aside aside aside-dark aside-hoverable drawer drawer-start";
      if (hidediv.style === "block") {
        hidediv.style = "none";
        kt_aside_id.className =
            "Aside aside aside-dark aside-hoverable drawer drawer-start";
      }
    }
  };

  var mobileHide = function () {
    var kt_aside_id = document.getElementById("kt_aside");
    var hidediv = document.getElementById("hidediv");
    hidediv.style.display = "none";
    kt_aside_id.className =
        "Aside aside aside-dark aside-hoverable drawer drawer-start";
  };
  var kt_aside_id = document.getElementById("kt_content");
  var body_id = document.getElementById("kt_body");
  var logo = document.getElementById("kt_aside_logo");
  var hidediv = document.getElementById("hidediv");

  var mobileAside = document.getElementById("kt_aside_mobile_toggle");
  if (mobileAside) {
    mobileAside.addEventListener("click", mobileFunction, false);
  }
  // body_id.addEventListener("click", clicklogo);
  hidediv.addEventListener("click", mobileHide);
};
</script>
