import Vue from "vue";
// import VeeValidate from 'vee-validate';
import { ValidationProvider, ValidationObserver, extend , localize } from 'vee-validate';

// Install English and Arabic locales.
import ar from 'vee-validate/dist/locale/ar.json';
// Set vee-validate language
localize({'ar': ar});
localize('ar');
import * as rules from 'vee-validate/dist/rules';

// install rules and localization
Object.keys(rules).forEach(rule => {
    extend(rule, rules[rule]);
});
// Install components globally
Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);

