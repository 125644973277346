import Vue from "vue";
import VueSimpleAlert from "vue-simple-alert";
Vue.use(VueSimpleAlert);

import VueSweetalert2 from 'vue-sweetalert2';
//import 'sweetalert2/dist/sweetalert2.min.css';
Vue.use(VueSweetalert2);

// Vue.use(VueSimpleAlert, { reverseButtons: true });
import Swal from 'sweetalert2'
window.Swal = Swal;
