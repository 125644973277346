<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'app',
  computed: {

  },
  watch:{
    $route (to, from){
      //
    }
  }
};
</script>


<style scoped >
  @import "~bootstrap/dist/css/bootstrap.min.css";
  @import "~bootstrap/dist/css/bootstrap.css";
  @import "~bootstrap-vue/dist/bootstrap-vue.css";
  @import "~bootstrap/dist/css/bootstrap.rtl.min.css";
  @import "~bootstrap/dist/css/bootstrap.rtl.css";
  @import "~v-toaster/dist/v-toaster.css";
  @import "~vue-multiselect/dist/vue-multiselect.min.css";
  @import "~vue2-datepicker/index.css";
  @import "assets/css/plugins.bundle.rtl.css";
  @import "assets/css/style.bundle.rtl.css";
  @import "assets/css/wizard.css";
  @import "assets/css/cs-wizard.css";
  @import "assets/css/main.css";
</style>
