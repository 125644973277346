<template>
  <div
      class="Dashboard content d-flex flex-column flex-column-fluid"
      id="kt_content"
  >
    <!--begin::Toolbar-->
    <div class="toolbar" id="kt_toolbar">
      <!--begin::Container-->
      <div id="kt_toolbar_container" class="container-fluid d-flex flex-stack">
        <!--begin::Page title-->
        <div
            data-kt-place="true"
            data-kt-place-mode="prepend"
            data-kt-place-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}"
            class="
            page-title
            d-flex
            align-items-center
            me-3
            flex-wrap
            mb-5 mb-lg-0
            lh-1
          "
        >
          <!--&lt;!&ndash;begin::Title&ndash;&gt;-->
          <!--<h1 class="d-flex align-items-center text-dark fw-bolder my-1 fs-3">-->
          <!--<span class="fa fa-cogs"></span>-->
          <!--Albums-->
          <!--</h1>-->
          <!--&lt;!&ndash;end::Title&ndash;&gt;-->
          <!--&lt;!&ndash;begin::Separator&ndash;&gt;-->
          <!--<span class="h-20px border-gray-200 border-start mx-4"></span>-->
          <!--&lt;!&ndash;end::Separator&ndash;&gt;-->
          <!--begin::Breadcrumb-->
          <ul class="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
            <!--begin::Item-->
            <li class="breadcrumb-item text-muted">
                <router-link to="/" class="text-success fw-bolder">
                <span class="fa fa-home"></span>
                الرئيسية
              </router-link>
            </li>
            <!--end::Item-->
          </ul>
          <!--end::Breadcrumb-->
        </div>
        <!--end::Page title-->
        <!--begin::Actions-->
        <div class="d-flex align-items-center py-1">
          <!--begin::Button-->

          <!--end::Button-->
        </div>
        <!--end::Actions-->
      </div>
      <!--end::Container-->
    </div>
    <!--end::Toolbar-->
    <!--begin::Post-->
    <div class="post d-flex flex-column-fluid" id="kt_post">
      <!--begin::Container-->
      <div id="kt_content_container" class="container">
        <!--begin::Card-->
        <div class="card card-xxl-stretch">
          <!--begin::Header-->
          <div class="card-header border-0">
            <div class="card-toolbar">

              <!--end::Menu-->
            </div>
          </div>
          <!--end::Header-->
          <!--begin::Body-->
          <div class="card-body p-0">
            <!--begin::Stats-->
            <div class="card-p mt-n20 position-relative">
              <!--begin::Row-->
              <div class="row g-0">

                 </div>
              <!--end::Row-->

<!--begin::Tables Widget 9-->
              <div class="card mb-5 mb-xl-10">
                <!--begin::Header-->
                <div class="row gy-5 g-xl-10">
                  <div class="col-xl-12">
                    <div class="card-header border-0 pt-5">
                      <h3 class="card-title align-items-start flex-column text-success fw-bolder">
                          <span >
                             <span class="fas fa-check "></span>
                            <span class="ml-2 me-2">التراخيص</span>
                          </span>
                        <span class="text-muted mt-1 fw-bold fs-7">
                     

                        </span>
                      </h3>
                      
                    </div>

                    <!--end::Header-->
                    <!--begin::Body-->
                    <div class="card-body py-3">
                    <div class="row mb-5"  v-if="row.licenses.length == 0">
                     <div class="no-record-block bg-light-warning text-warning">
                       <div class="row text-center">
                         <div class="col-lg-12 text-center">
                           <label class="fw-bolder">لايوجد تراخيص للعرض </label>
                         </div>
                       </div>
                     </div>
                   </div>
                      <div class="table-responsive" v-if="row.licenses.length > 0">
                        <table class="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4 ">
                          <thead>
                            <tr class=" text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0">
                                <th class="text-center">#</th>
                                <th class="text-center">اسم صاحب الترخيص </th>
                                <th class="text-center">رقم هوية </th>
                                <th class="text-center">رقم الترخيص</th>
                                <th class="text-center">نوع الترخيص</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(x, index) in row.licenses" :key="index">
                                <td class="text-center">{{ (index+1) }}</td>
                                <td class="text-center">{{ x.person_name }}</td>
                                <td class="text-center">{{ x.person_id_no }}</td>
                                <td class="text-center">{{ x.no }}</td>
                                <td class="text-center">{{ x.type_name }}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <!--end::Body-->
                  </div>
                </div>
              </div>
              <!--end::Tables Widget 9-->             

<!--begin::Tables Widget 9-->
              <div class="card mb-5 mb-xl-10">
                <!--begin::Header-->
                <div class="row gy-5 g-xl-10">
                  <div class="col-xl-12">
                    <div class="card-header border-0 pt-5">
                      <h3 class="card-title align-items-start flex-column text-success fw-bolder">
                          <span >
                             <span class="fas fa fa-rocket "></span>
                            <span class="ml-2 me-2">الاشتراكات </span>
                          </span>
                        <span class="text-muted mt-1 fw-bold fs-7">
                     

                        </span>
                      </h3>
                      
                    </div>

                    <!--end::Header-->
                    <!--begin::Body-->
                    <div class="card-body py-3">
                    <div class="row mb-5"  v-if="row.subscriptions.length == 0">
                     <div class="no-record-block bg-light-warning text-warning">
                       <div class="row text-center">
                         <div class="col-lg-12 text-center">
                           <label class="fw-bolder">لايوجد اشتراكات للعرض </label>
                         </div>
                       </div>
                     </div>
                   </div>
                      <div class="table-responsive" v-if="row.subscriptions.length > 0">
                        <table class="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4 ">
                          <thead>
                            <tr class=" text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0">
                                <th class="text-center">#</th>
                                <th class="text-center">اسم المشترك </th>
                                <th class="text-center">رقم هوية </th>
                                <th class="text-center">رقم الاشتراك</th>
                                <th class="text-center">نوع الاشتراك</th>
                                <th class="text-center">رقم المنزل</th>
                                <th class="text-center">رقم الشارع</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(x, index) in row.subscriptions" :key="index">
                                <td class="text-center">{{ (index+1) }}</td>
                                <td class="text-center">{{ x.person_name }}</td>
                                <td class="text-center">{{ x.person_id_no }}</td>
                                <td class="text-center">{{ x.no }}</td>
                                <td class="text-center">{{ x.type_name }}</td>
                                <td class="text-center">{{ x.house_no }}</td>
                                <td class="text-center">{{ x.street_no }}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <!--end::Body-->
                  </div>
                </div>
              </div>
              <!--end::Tables Widget 9-->             

            </div>
            <!--end::Stats-->
          </div>
          <!--end::Body-->
        </div>
        <!--end::Card-->
      </div>
      <!--end::Container-->
    </div>
    <!--end::Post-->
  </div>
</template>

<script>
import ApiServices from "../core/services/api.services";

export default {
  name: "Dashboard",
  components: {
    // Toolbar ,
  },
  data() {
    return {
      list: [],
      row: {licenses :[], subscriptions :[]},
      loading: false,
      req: {id: null, request_no: ' ',service_category_id: ' '},
      ModalTitle: "",
    };
  },
  methods: {
   loadContent : function (){
      ApiServices.get('template/dashboard').then(
          response => {
            if(response.data){
              this.row = response.data ;
            }
          },
          error => {
            this.$toaster.error(error);
          });
   },
    
  },
  created: function () {
    this.loadContent();

  },

  computed: {},
};
</script>