import Vue from "vue";
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
// import { far } from '@fortawesome/free-regular-svg-icons';
import {faHome, faUser, faUserPlus, faSignInAlt, faSignOutAlt , faImages , faUsers , faCogs , faTable , faEdit } from '@fortawesome/free-solid-svg-icons';
library.add( faHome, faUser, faUserPlus, faSignInAlt, faSignOutAlt , faImages , faUsers , faCogs , faTable , faEdit );
Vue.component('font-awesome-icon', FontAwesomeIcon);



