<template>
   <div class="Footer footer py-4 d-flex flex-lg-column" id="kt_footer">
    <!--begin::Container-->
    <div class="container-fluid d-flex flex-column flex-md-row align-items-center justify-content-between">
      <!--begin::Copyright-->
      <div class="text-dark order-2 order-md-1">
        <a href="https://nepras.ps/index.html#/" target="_blank" class="text-gray-800 text-hover-success">شركة نبراس للاعلام وتكنولوجيا المعلومات</a>
        <span class="text-muted fw-bold me-1">©</span>
        <span class="text-muted fw-bold me-1">2022</span>
      </div>
      <!--end::Copyright-->
    </div>
    <!--end::Container-->
  </div>
</template>

<script>
    export default {
        name: 'Footer',
        data() {
            return {
                // their_name: this.$route.params.name
            }
        }
    }
</script>
