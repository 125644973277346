import config from './config';

import axios from 'axios';
export const userService = { login, logout };

function login(email, password) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, password })
    };
    // return fetch(`${config.apiUrl}authenticate`, requestOptions)
    //     .then(handleResponse)
    //     .then(user => {
    //         // login successful if there's a jwt token in the response
    //         if (user.token) {
    //             // store user details and jwt token in local storage to keep user logged in between page refreshes
    //             resolve({ ok: true, text: () => Promise.resolve(JSON.stringify(responseJson)) });
    //             localStorage.setItem('user', JSON.stringify(user));
    //         }
    //
    //         return user;
    //     });

    return axios.post('http://localhost/_mobadarat-backend/public/api/v1.0/authenticate', {
    // return axios.post(`${apiUrl}authenticate`, {
        email: email,
        password: password
    })
        .then(response => {
        if (response.data.token) {
        localStorage.setItem('user', JSON.stringify(response.data));
    }
    return response.data;
});
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}